import 'font-awesome/css/font-awesome.min.css';
import './assets/css/app.css';

import DashboardPage from './pages/DashboardPage';
import TypographyPage from './pages/TypographyPage'
import LoginPage from './pages/auth/LoginPage'
import ResetPassword from './pages/auth/ResetPassword';
import ProfilePage from './pages/profile/ProfilePage';
import ChangePasswordPage from './pages/profile/ChangePasswordPage';
import UserPreferencesPage from './pages/profile/UserPreferencesPage'
import AdminBlankPage from './pages/AdminBlankPage';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import CustomerRegister from './pages/CustomerRegister';

import AyaList from './ListPages/AyaList';
import CustomerList from './ListPages/CustomerList';
import AyaReg from './pages/AyaReg';
import AyaDetails from './DetailPage/AyaDetails';
import CustomerDetail from './DetailPage/CustomerDetail';
import AyaAssign from './DetailPage/AyaAssign';
import CustomerAssign from './DetailPage/CustomerAssign';
import Attendence from './pages/Attendence';
import Booking from './pages/Booking';
import TestBooking from './pages/auth/TestBooking';
import Payment from './pages/Payment';
import PaymentEdit from './pages/PaymentEdit';
import AyaPayment from './PaymentPage/AyaPayment';
import AyaPaymentList from './PaymentPage/AyaPaymentList';
import Test from './ListPages/Test';
import TotalAvailbleWork from './navigationPage/TotalAvailbleWork';
import TotalAvailbleCustomer from './navigationPage/TotalAvailbleCustomer';
import SecurityMoney from './navigationPage/SecurityMoney';
import TotalAmountRec from './navigationPage/TotalAmountRec';
import TotalAyaPayment from './navigationPage/TotalAyaPayment';
import CustomerPaymentReceipt from './pages/newPages/CustomerPaymentReceipt';
import AyaPaymentReceipt from './pages/newPages/AyaPaymentReceipt';
import AyaBill from './pages/newPages/AyaBill';
import CustomerBill from './pages/newPages/CustomerBill';
import SecurityDeposit from './pages/newPages/SecurityDeposit';
import NotSecurityMoney from './navigationPage/NotSecurityMoney';
import CustomerLocal from './pages/newPages/CustomerLocal';
import CustomerOutstation from './pages/newPages/CustomerOutstation';
import LocalAya from './pages/newPages/LocalAya';
import OutstationAya from './pages/newPages/OutstationAya';


function App() {
    return (
        <Router>
            <Routes>
                {/* <Route exact path='/' element={<DashboardPage />} /> */}
                <Route exact path='/' element={<LoginPage />} />
                <Route exact path='/dashboard' element={<DashboardPage />} />
                <Route exact path='/reset-password' element={<ResetPassword />} />
                <Route exact path='/profile' element={<ProfilePage />} />
                <Route exact path='/change-password' element={<ChangePasswordPage />} />
                <Route exact path='/preferences' element={<UserPreferencesPage />} />
                <Route exact path='/typography' element={<TypographyPage />} />
                <Route exact path='/blank-page' element={<AdminBlankPage />} />
                <Route exact path='/customer' element={<CustomerRegister />} />
                <Route exact path='/ayareg' element={<AyaReg />} />
                <Route exact path='/ayaList' element={<AyaList />} />
                <Route exact path='/customerlist' element={<CustomerList />} />
                <Route exact path='/ayaDetail' element={<AyaDetails />} />
                <Route exact path='/test' element={<Test />} />
                {/* <Route exact path='/test2' element={<Test2 />} /> */}
                {/* <Route exact path='/ayaassign' element={<AyaAssign />} /> */}

                <Route exact path='/booking' element={<Booking />} />
                <Route exact path='/testbooking' element={<TestBooking />} />
                <Route exact path='/payment' element={<Payment />} />
                <Route exact path='/ayapayment' element={<AyaPaymentList />} />

                <Route path="ayareg" element="">
                    <Route path=':id' element={<AyaDetails />} />
                </Route>
                <Route path="customerreg" element="">
                    <Route path=':id' element={<CustomerDetail />} />
                </Route>
                <Route path="customerassign" element="">
                    <Route path=':id' element={<CustomerAssign />} />
                </Route>
                <Route path="payment" element="">
                    <Route path=':id' element={<PaymentEdit />} />
                </Route>
                <Route path="ayaassign" element="">
                    <Route path=':id' element={<AyaAssign />} />
                </Route>
                <Route path="ayapayment" element="">
                    <Route path=':id' element={<AyaPayment />} />
                </Route>



                <Route exact path='/notgivensecurity' element={<NotSecurityMoney />} />

                <Route exact path='/localcustomer' element={<CustomerLocal />} />
                <Route exact path='/outstationCustomer' element={<CustomerOutstation />} />

                <Route exact path='/localaya' element={<LocalAya />} />
                <Route exact path='/outstationaya' element={<OutstationAya />} />




                <Route exact path='/attendence' element={<Attendence />} />
                <Route exact path='/totalAvaibleNanny' element={<TotalAvailbleWork />} />
                <Route exact path='/totalAvaibleCustomer' element={<TotalAvailbleCustomer />} />
                <Route exact path='/customerBill' element={<TotalAvailbleCustomer />} />

                <Route exact path='/securityAmount' element={<SecurityMoney />} />
                <Route exact path='/totalAmount' element={<TotalAmountRec />} />
                <Route exact path='/totalAyapaid' element={<TotalAyaPayment />} />
                {/* <Route path='generateBill' element = {<CustomerPaymentReceipt/>}/> */}
                <Route path='generateCustomerBill' element={<CustomerPaymentReceipt />} />
                <Route path='generateAyaBill' element={<AyaPaymentReceipt />} />
                <Route path='generateSecurityBill' element={<SecurityDeposit />} />
                <Route path="generateAyaBill" element="">
                    <Route path=':id' element={<AyaPaymentReceipt />} />
                </Route>

                <Route path="generateCustomerBill" element="">
                    <Route path=':id' element={<CustomerPaymentReceipt />} />
                </Route>

                {/* <Route path = ':id' element = {<CustomerPaymentReceipt/>}/> */}
                {/* </Route> */}
            </Routes>
        </Router>
    )
}

export default App;


// import React from 'react';
// import 'font-awesome/css/font-awesome.min.css';
// import './assets/css/app.css';

// import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
// import LoginPage from './pages/auth/LoginPage';
// import ResetPassword from './pages/auth/ResetPassword';
// import DashboardPage from './pages/DashboardPage';
// import ProfilePage from './pages/profile/ProfilePage';
// import ChangePasswordPage from './pages/profile/ChangePasswordPage';
// import UserPreferencesPage from './pages/profile/UserPreferencesPage';
// import TypographyPage from './pages/TypographyPage';
// import AdminBlankPage from './pages/AdminBlankPage';
// import CustomerRegister from './pages/CustomerRegister';
// import AyaList from './ListPages/AyaList';
// import CustomerList from './ListPages/CustomerList';
// import AyaReg from './pages/AyaReg';
// import AyaDetails from './DetailPage/AyaDetails';
// import CustomerDetail from './DetailPage/CustomerDetail';
// import AyaAssign from './DetailPage/AyaAssign';
// import CustomerAssign from './DetailPage/CustomerAssign';
// import Attendence from './pages/Attendence';
// import Booking from './pages/Booking';
// import TestBooking from './pages/auth/TestBooking';
// import Payment from './pages/Payment';
// import PaymentEdit from './pages/PaymentEdit';
// import AyaPayment from './PaymentPage/AyaPayment';
// import AyaPaymentList from './PaymentPage/AyaPaymentList';
// import Test from './ListPages/Test';
// import TotalAvailbleWork from './navigationPage/TotalAvailbleWork';
// import TotalAvailbleCustomer from './navigationPage/TotalAvailbleCustomer';
// import SecurityMoney from './navigationPage/SecurityMoney';
// import TotalAmountRec from './navigationPage/TotalAmountRec';
// import TotalAyaPayment from './navigationPage/TotalAyaPayment';
// import CustomerPaymentReceipt from './pages/newPages/CustomerPaymentReceipt';
// import AyaPaymentReceipt from './pages/newPages/AyaPaymentReceipt';
// import AyaBill from './pages/newPages/AyaBill';
// import CustomerBill from './pages/newPages/CustomerBill';
// import SecurityDeposit from './pages/newPages/SecurityDeposit';
// import NotSecurityMoney from './navigationPage/NotSecurityMoney';
// import CustomerLocal from './pages/newPages/CustomerLocal';
// import CustomerOutstation from './pages/newPages/CustomerOutstation';
// import LocalAya from './pages/newPages/LocalAya';
// import OutstationAya from './pages/newPages/OutstationAya';

// // Utility function to check for token
// const isAuthenticated = () => {
//     return !!localStorage.getItem('token');
// };

// // PrivateRoute Component
// const PrivateRoute = ({ element: Component, ...rest }) => {
//     return isAuthenticated() ? <Component {...rest} /> : <Navigate to="/" replace />;
// };

// function App() {
//     return (
//         <Router>
//             <Routes>
//                 {/* Public Routes */}
//                 <Route exact path="/" element={<LoginPage />} />
//                 <Route exact path="/reset-password" element={<ResetPassword />} />

//                 {/* Private Routes */}
//                 <Route exact path="/dashboard" element={<PrivateRoute element={DashboardPage} />} />
//                 <Route exact path="/profile" element={<PrivateRoute element={ProfilePage} />} />
//                 <Route exact path="/change-password" element={<PrivateRoute element={ChangePasswordPage} />} />
//                 <Route exact path="/preferences" element={<PrivateRoute element={UserPreferencesPage} />} />
//                 <Route exact path="/typography" element={<PrivateRoute element={TypographyPage} />} />
//                 <Route exact path="/blank-page" element={<PrivateRoute element={AdminBlankPage} />} />
//                 <Route exact path="/customer" element={<PrivateRoute element={CustomerRegister} />} />
//                 <Route exact path="/ayareg" element={<PrivateRoute element={AyaReg} />} />
//                 <Route exact path="/ayaList" element={<PrivateRoute element={AyaList} />} />
//                 <Route exact path="/customerlist" element={<PrivateRoute element={CustomerList} />} />
//                 <Route exact path="/ayaDetail" element={<PrivateRoute element={AyaDetails} />} />
//                 <Route exact path="/test" element={<PrivateRoute element={Test} />} />
//                 <Route exact path="/booking" element={<PrivateRoute element={Booking} />} />
//                 <Route exact path="/testbooking" element={<PrivateRoute element={TestBooking} />} />
//                 <Route exact path="/payment" element={<PrivateRoute element={Payment} />} />
//                 <Route exact path="/ayapayment" element={<PrivateRoute element={AyaPaymentList} />} />
//                 <Route exact path="/attendence" element={<PrivateRoute element={Attendence} />} />
//                 <Route exact path="/totalAvaibleNanny" element={<PrivateRoute element={TotalAvailbleWork} />} />
//                 <Route exact path="/totalAvaibleCustomer" element={<PrivateRoute element={TotalAvailbleCustomer} />} />
//                 <Route exact path="/customerBill" element={<PrivateRoute element={TotalAvailbleCustomer} />} />
//                 <Route exact path="/securityAmount" element={<PrivateRoute element={SecurityMoney} />} />
//                 <Route exact path="/totalAmount" element={<PrivateRoute element={TotalAmountRec} />} />
//                 <Route exact path="/totalAyapaid" element={<PrivateRoute element={TotalAyaPayment} />} />
//                 <Route exact path="/notgivensecurity" element={<PrivateRoute element={NotSecurityMoney} />} />
//                 <Route exact path="/localcustomer" element={<PrivateRoute element={CustomerLocal} />} />
//                 <Route exact path="/outstationCustomer" element={<PrivateRoute element={CustomerOutstation} />} />
//                 <Route exact path="/localaya" element={<PrivateRoute element={LocalAya} />} />
//                 <Route exact path="/outstationaya" element={<PrivateRoute element={OutstationAya} />} />

//                 {/* Nested Routes */}
//                 <Route path="/ayareg" element="">
//                     <Route path=":id" element={<PrivateRoute element={AyaDetails} />} />
//                 </Route>
//                 <Route path="/customerreg" element="">
//                     <Route path=":id" element={<PrivateRoute element={CustomerDetail} />} />
//                 </Route>
//                 <Route path="/customerassign" element="">
//                     <Route path=":id" element={<PrivateRoute element={CustomerAssign} />} />
//                 </Route>
//                 <Route path="/payment" element="">
//                     <Route path=":id" element={<PrivateRoute element={PaymentEdit} />} />
//                 </Route>
//                 <Route path="/ayaassign" element="">
//                     <Route path=":id" element={<PrivateRoute element={AyaAssign} />} />
//                 </Route>
//                 <Route path="/ayapayment" element="">
//                     <Route path=":id" element={<PrivateRoute element={AyaPayment} />} />
//                 </Route>
//                 <Route path="/generateAyaBill" element="">
//                     <Route path=":id" element={<PrivateRoute element={AyaPaymentReceipt} />} />
//                 </Route>
//                 <Route path="/generateCustomerBill" element="">
//                     <Route path=":id" element={<PrivateRoute element={CustomerPaymentReceipt} />} />
//                 </Route>
//             </Routes>
//         </Router>
//     );
// }

// export default App;
